import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const QuoteDetails = styled(RichTextWithModal)`
  ${RichText} {
    p {
      font-weight: normal;

      ${fonts.headingXSmall};

      ${mediaQuery.tabletLandscape`
      ${fonts.paragraphLarge}
    `}
    }

    margin-bottom: ${spacing(4)};

    ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(5)};
  `}
  }
`;

export const DocumentsDispatchingDetails = styled(RichTextWithModal)`
  ${RichText} {
    p {
      ${mediaQuery.tabletLandscape`
      ${fonts.paragraphLarge}
    `}
    }
  }
`;

export const StyledGridItem = styled(GridItem)`
  margin-top: ${spacing(2)};
  margin-bottom: ${spacing(1)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(3)};
    margin-top: ${spacing(4)};
  `}
`;

export const FooterContainer = styled.div`
  div > div > div > div > div {
    ${mediaQuery.tabletPortrait`
      margin: 0 ${spacing(1.5)};
      width: inherit;
    `}
  }
`;

export const StyledPanel = styled.div`
  background-color: ${colors.neutral08};
  border-left: 4px ${colors.notificationInfo} solid;
  padding: ${spacing(3)};
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  ${mediaQuery.tabletPortrait`
    padding: ${spacing(4)};
    `};
  margin-top: ${spacing(2)};
  margin-bottom: ${spacing(2)};
`;

export const StyledHeading = styled.h2`
  ${fonts.headingSmall}
  margin-top: 0;
  line-height: 1;
`;

export const LargeText = styled(RichTextWithModal)`
  ${RichText} {
    p {
      font-size: 1.25rem;
    }
  }
`;
