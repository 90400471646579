import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import React from 'react';
import { PageTitle, trackTextButtonClick } from 'helpers/eventTracking';
import { CsIcon } from 'types/contentStack';
import {
  BodyWrapper,
  BuyapowaPromotionWrapper,
  IconWrapper,
  StyledHeading,
  StyledPromotionLink,
  StyledRichText,
} from './styles';

export type BuyapowaPromotionPropsCs = {
  heading: string;
  body: string;
  title: string;
  offers_icon: [CsIcon] | [];
  url: {
    href: string;
    title: string;
  };
};

export type BuyapowaPromotionProps = {
  heading: string;
  html: string;
  ctaBody: string;
  offers_icon?: string;
  arrow_icon?: string;
  href: string;
};

const BuyapowaPromotion: React.FC<BuyapowaPromotionProps> = ({
  heading,
  html,
  ctaBody,
  offers_icon,
  arrow_icon,
  href,
}) => (
  <BuyapowaPromotionWrapper data-cy="buyapowa-promotion">
    <IconWrapper>{offers_icon && <Icon name={offers_icon} size="large" />}</IconWrapper>
    <BodyWrapper>
      <StyledHeading>{heading}</StyledHeading>
      <StyledRichText html={html} />
      <StyledPromotionLink
        openInNewTab
        href={href}
        onClick={() => trackTextButtonClick(PageTitle.Confirmation, ctaBody)}>
        {ctaBody}
        {arrow_icon && <Icon name={arrow_icon} size="small" />}
      </StyledPromotionLink>
    </BodyWrapper>
  </BuyapowaPromotionWrapper>
);

export default BuyapowaPromotion;
