import { twoLayeredBoxShadow } from '@rsa-digital/evo-shared-components/commonStyles/styling';
import FormFooter from '@rsa-digital/evo-shared-components/components/Form/FormFooter';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import LinkWithIcon from 'components/LinkWithIcon';

export const FormFooterWithTopMargin = styled(FormFooter)`
  margin-top: ${spacing(8)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(6)};
  `}
`;

export const StyledLinkWithIcon = styled(LinkWithIcon)`
  margin: 0;
  border: 2px solid ${colors.core01};
  &&&& {
    display: inline-block;
    padding: ${spacing(1)} ${spacing(3)} ${spacing(1)} ${spacing(2)};
    border-radius: ${spacing(4)};
    background: ${colors.neutral07};
      &:after {
        border-radius: ${spacing(4)};
      }
      &:active {
        &:after {
          border-radius: ${spacing(4)};
        }
      }
      &:hover, &:focus-visible {
        &:after {
          top: -2px;
          bottom: -2px;
          left: -1px;
          right: -1px;
          box-shadow: ${twoLayeredBoxShadow(
            colors.selectorHighlight,
            colors.selectorHighlightWithTransparency
          )};
          border-radius: ${spacing(4)};
        }
      }
  }
   margin-top: ${spacing(2)};
   
  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(2.5)};
    :not(:last-child) {
      margin-bottom: 0;
      margin-right: ${spacing(6)};
    }
  `}

  ${mediaQuery.tabletLandscape`
      margin-left: ${spacing(6)};
      margin-right: 0;
  `}
  ${mediaQuery.desktop`
      margin-left: ${spacing(10)};
      margin-right: 0;
  `}
`;
