import { SecondaryButton } from '@rsa-digital/evo-shared-components/components/Button';
import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { heavyFontWeight } from '@rsa-digital/evo-shared-components/theme/Tesco/fonts';
import styled from 'styled-components';

export const BuyapowaPromotionWrapper = styled.div`
  background-color: ${colors.neutral08};
  border-left: 4px ${colors.notificationInfo} solid;
  padding: ${spacing(3)};
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  ${mediaQuery.tabletPortrait`
    padding: ${spacing(4)};
    `};
  margin-bottom: ${spacing(2)};
  margin-top: ${spacing(5)};
  display: flex;
`;

export const StyledRichText = styled(RichText)`
  ${fonts.paragraph};
  && {
    font-weight: ${heavyFontWeight};
  }
`;

export const StyledHeading = styled.h2`
  ${fonts.headingSmall}
  margin-top: 0;
  line-height: 1;
`;

export const BodyWrapper = styled.div``;

export const StyledCTA = styled(SecondaryButton)``;

export const IconWrapper = styled.div`
  padding: 3px ${spacing(3)} 0 0;
  svg {
    fill: ${colors.core01};
  }
`;

export const StyledPromotionLink = styled(SecondaryButton)`
  margin-top: ${spacing(3)};
  ${Icon} {
    margin-left: ${spacing(2)};
  }
`;
