import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import PromotionLink from '@rsa-digital/evo-shared-components/components/Link';
import React from 'react';
import { PageTitle, trackTextButtonClick } from 'helpers/eventTracking';
import { CsIcon } from 'types/contentStack';
import { StyledPanelWithTopLabel, Wrapper } from './styles';

export type ProductPromotionProps = {
  label: string;
  products: {
    [key: string]: {
      title: string;
      href: string;
    };
  };
  iconCode?: string;
};

export type ProductPromotionPropsCs = {
  label: string;
  products: {
    [key: string]: {
      title: string;
      href: string;
    };
  };
  arrow_icon: [CsIcon] | [];
};

const ProductPromotion: React.FC<ProductPromotionProps> = ({
  label,
  products,
  iconCode,
}) => {
  const productsArr = Object.values(products);

  return (
    <StyledPanelWithTopLabel label={label}>
      {productsArr.map((product) => (
        <Wrapper>
          <PromotionLink
            openInNewTab
            href={product.href}
            onClick={() => trackTextButtonClick(PageTitle.Confirmation, product.title)}>
            {product.title}
            {iconCode && <Icon size="small" name={iconCode} />}
          </PromotionLink>
        </Wrapper>
      ))}
    </StyledPanelWithTopLabel>
  );
};

export default ProductPromotion;
