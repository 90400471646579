import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import PromotionLink from '@rsa-digital/evo-shared-components/components/Link';
import PanelWithTopLabel from '@rsa-digital/evo-shared-components/components/Panel/PanelWithTopLabel';
import {
  ContentContainer,
  TopLabel,
} from '@rsa-digital/evo-shared-components/components/Panel/PanelWithTopLabel/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledPanelWithTopLabel = styled(PanelWithTopLabel)`
  margin: ${spacing(4)} 0 ${spacing(4)};
  ${TopLabel} {
    ${fonts.paragraphLarge};
    padding: ${spacing(2)};
    height: auto;
    justify-content: flex-start;
    & {
      font-weight: 700;
      color: ${colors.core02};
    }
  }

  ${ContentContainer} {
    display: flex;
    flex-flow: column;
    gap: ${spacing(2)};
    background-color: ${colors.core02};
  }

  ${Icon} {
    margin-left: ${spacing(2)};
  }

  ${PromotionLink} {
    ${fonts.paragraph};
    text-decoration: underline;
    & {
      color: ${colors.core01};
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  gap: ${spacing(2)};
`;
